import React from 'react';
import { connect } from 'react-redux';
import { deletePortaluserPesan } from './redux/reduxApi';
import { closeModal } from '../../../app/modals/redux/modalActions';
import Modal from '../../../ui/Modal';
import Button from '../../../ui/Button';

const actions = {
  closeModal,
  deletePortaluserPesan,
};

function ModalDeletePesanItem({ data, deletePortaluserPesan, closeModal }) {
  const { auth, history, item } = data;

  function handleDelete() {
    deletePortaluserPesan(auth, history, item);
    closeModal();
  }

  return (
    <Modal variant='danger' closeModal={closeModal}>
      <p className='my-3 mx-1'>
        Apakah Anda yakin ingin menghapus pesan{' '}
        <span className='has-text-danger has-text-weight-semibold is-capitalized'>{`${item.title}`}</span>{' '}
        ?
      </p>
      <div className='flex justify-end align-center pt-2'>
        <div className='buttons'>
          <Button cvariant='cdelete' onClick={handleDelete} />
          <Button cvariant='cback' onClick={closeModal} />
        </div>
      </div>
    </Modal>
  );
}

export default connect(null, actions)(ModalDeletePesanItem);
