import React from 'react';
import { connect } from 'react-redux';
import { deleteSyncScores } from './redux/reduxApi';
import { closeModal } from '../../../app/modals/redux/modalActions';
import Modal from '../../../ui/Modal';
import Button from '../../../ui/Button';

const actions = {
  closeModal,
  deleteSyncScores,
};

function ModalDeleteSyncScore({ data, deleteSyncScores, closeModal }) {
  const { auth, history, id, item } = data;

  function onDeleteSync() {
    deleteSyncScores(auth, history, id, item);
    closeModal();
  }
  return (
    <Modal variant='danger' closeModal={closeModal}>
      <div className='flex justify-around flex-column align-center'>
        <p className='is-size-5 has-text-centered mb-5 mt-5'>
          Apakah Anda yakin ingin menghapus status Sync Nilai?
        </p>
        <div className='flex flex-column justify-start'>
          <p className='is-size-6 is-italic mb-2'>
            <span className='tag is-info is-rounded'>Note:</span>
          </p>
          <p className=' is-italic'>
            1. Jika status sync nilai dihapus, sync nilai akan bisa dilakukan
            lagi dan tidak menghapus sync nilai yang telah dilakukan.
          </p>
          <p className=' is-italic'>
            2. Hapus manual nilai sebelumnya jika ingin melakukan sync nilai
            lagi, menghindari duplikat nilai.
          </p>
          <p className=' is-italic'>
            3. Jika dilakukan sync nilai kembali, kolom nilai yang terisi adalah
            kolom nilai yang masih kosong.
          </p>
        </div>
      </div>
      <div className='flex justify-end align-center pt-2'>
        <div className='buttons'>
          <Button cvariant='cdelete' onClick={onDeleteSync} />
          <Button cvariant='cback' onClick={closeModal} />
        </div>
      </div>
    </Modal>
  );
}

export default connect(null, actions)(ModalDeleteSyncScore);
