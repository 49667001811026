export const Menus = [
  {
    id: 'dashboard',
    icon: 'view-dashboard',
    title: 'Dashboard',
    alias: 'dashboard',
    auth: { dashboard_view: false },
    url: '/dashboard',
    order: '001',
    subm: [],
  },
  {
    id: 'profil',
    icon: 'account-box',
    title: 'Profil',
    alias: 'profil',
    auth: { profil_view: false },
    url: '/profil',
    order: '002',
    subm: [],
  },
  {
    id: 'rpp',
    icon: 'notebook-outline',
    title: 'RPP',
    alias: 'rpp',
    auth: {
      rpp_view: false,
      rpp_add: false,
      rpp_edit: false,
      rpp_publish: false,
      rpp_delete: false,
      rpp_all: false,
    },
    url: '/rpp',
    order: '003',
    subm: [],
  },
  {
    id: 'pembelajaran',
    icon: 'bookshelf',
    title: 'Pembelajaran',
    alias: 'pembelajaran',
    auth: {
      pembelajaran_view: false,
    },
    url: '/pembelajaran',
    order: '004',
    subm: [
      {
        id: 'materi',
        m: 'pembelajaran',
        submenuTitle: 'Materi',
        alias: 'pembelajaran/materi',
        auth: {
          materi_view: false,
          materi_add: false,
          materi_edit: false,
          materi_publish: false,
          materi_delete: false,
          materi_all: false,
        },
        subUrl: '/pembelajaran/materi',
        order: '001',
      },
      {
        id: 'tugas',
        m: 'pembelajaran',
        submenuTitle: 'Tugas',
        alias: 'pembelajaran/tugas',
        auth: {
          tugas_view: false,
          tugas_add: false,
          tugas_copy: false,
          tugas_edit: false,
          tugas_publish: false,
          tugas_delete: false,
          tugas_all: false,
          tugas_score: false,
          tugas_copyScore: false,
          tugas_export: false,
          tugas_publishScore: false,
          tugas_syncScore: false,
          tugas_deleteSync: false,
          tugas_deleteStudentAnswer: false,
          tugas_deleteAllStudentAnswer: false,
        },
        subUrl: '/pembelajaran/tugas',
        order: '002',
      },
      {
        id: 'latihan',
        m: 'pembelajaran',
        submenuTitle: 'Latihan',
        alias: 'pembelajaran/latihan',
        auth: {
          latihan_view: false,
          latihan_add: false,
          latihan_edit: false,
          latihan_publish: false,
          latihan_delete: false,
          latihan_all: false,
        },
        subUrl: '/pembelajaran/latihan',
        order: '003',
      },
      {
        id: 'tes',
        m: 'pembelajaran',
        submenuTitle: 'Tes',
        alias: 'pembelajaran/tes',
        auth: {
          tes_view: false,
          tes_add: false,
          tes_copy: false,
          tes_edit: false,
          tes_publish: false,
          tes_delete: false,
          tes_all: false,
          tes_score: false,
          tes_copyScore: false,
          tes_export: false,
          tes_publishScore: false,
          tes_syncScore: false,
          tes_deleteSync: false,
          tes_deleteStudentAnswer: false,
          tes_deleteAllStudentAnswer: false,
        },
        subUrl: '/pembelajaran/tes',
        order: '004',
      },
      {
        id: 'monitoring',
        m: 'pembelajaran',
        submenuTitle: 'Monitoring',
        alias: 'pembelajaran/monitoring',
        auth: {
          monitoring_view: false,
          monitoring_note: false,
          monitoring_pesan: false,
          monitoring_block: false,
        },
        subUrl: '/pembelajaran/monitoring',
        order: '005',
      },
    ],
  },
  // {
  //   id: 'cbt',
  //   icon: 'laptop',
  //   title: 'CBT',
  //   alias: 'cbt',
  //   auth: {
  //     cbt_view: false,
  //   },
  //   url: '/cbt',
  //   order: '006',
  //   subm: [
  //     {
  //       id: 'cbtparticipant',
  //       m: 'cbt',
  //       submenuTitle: 'Peserta',
  //       alias: 'cbt/pesertacbt',
  //       auth: {
  //         cbtparticipant_view: false,
  //         cbtparticipant_add: false,
  //         cbtparticipant_edit: false,
  //         cbtparticipant_publish: false,
  //         cbtparticipant_delete: false,
  //         cbtparticipant_import: false,
  //       },
  //       subUrl: '/cbt/pesertacbt',
  //       order: '001',
  //     },
  //     {
  //       id: 'cbtquestion',
  //       m: 'cbt',
  //       submenuTitle: 'Soal',
  //       alias: 'cbt/soalcbt',
  //       auth: {
  //         cbtquestion_view: false,
  //         cbtquestion_add: false,
  //         cbtquestion_edit: false,
  //         cbtquestion_publish: false,
  //         cbtquestion_import: false,
  //         cbtquestion_delete: false,
  //         cbtquestion_all: false,
  //         cbtquestion_score: false,
  //       },
  //       subUrl: '/cbt/soalcbt',
  //       order: '002',
  //     }
  //   ],
  // },
  {
    id: 'calendar',
    icon: 'calendar-month',
    title: 'Kalender',
    alias: 'calendar',
    auth: {
      calendar_view: false,
      calendar_add: false,
      calendar_edit: false,
      calendar_active: false,
      calendar_delete: false,
      calendar_import: false,
    },
    url: '/calendar',
    order: '007',
    subm: [],
  },
  {
    id: 'portal',
    icon: 'account-group',
    title: 'Portal',
    alias: 'portal',
    auth: { portal_view: false },
    url: '/portal',
    order: '008',
    subm: [
      {
        id: 'portaluser',
        m: 'portal',
        submenuTitle: 'User',
        alias: 'portal/portaluser',
        auth: {
          portaluser_edit: false,
          portaluser_view: false,
          portaluser_delete: false,
          portaluser_sync: false,
          portaluser_deleteall: false,
        },
        subUrl: '/portal/portaluser',
        order: '001',
      },
      {
        id: 'pesanpribadi',
        m: 'portal',
        submenuTitle: 'Pesan Pribadi',
        alias: 'portal/pesanpribadi',
        auth: {
          pesanpribadi_view: false,
          pesanpribadi_add: false,
          pesanpribadi_edit: false,
          pesanpribadi_publish: false,
          pesanpribadi_delete: false,
          pesanpribadi_all: false,
          pesanpribadi_import: false,
        },
        subUrl: '/portal/pesanpribadi',
        order: '002',
      },
      // {
      //   id: 'whatsapp',
      //   m: 'portal',
      //   submenuTitle: 'Whatsapp',
      //   alias: 'portal/whatsapp',
      //   auth: {
      //     whatsapp_view: false,
      //     whatsapp_add: false,
      //     whatsapp_edit: false,
      //     whatsapp_publish: false,
      //     whatsapp_import: false,
      //     whatsapp_delete: false,
      //     whatsapp_all: false,
      //   },
      //   subUrl: '/portal/whatsapp',
      //   order: '003',
      // },
      {
        id: 'slideshow',
        m: 'portal',
        submenuTitle: 'Slideshow',
        alias: 'portal/slideshow',
        auth: {
          slideshow_view: false,
          slideshow_add: false,
          slideshow_edit: false,
          slideshow_delete: false,
        },
        subUrl: '/portal/slideshow',
        order: '004',
      },
      {
        id: 'announcement',
        m: 'portal',
        submenuTitle: 'Pengumuman',
        alias: 'portal/pengumuman',
        auth: {
          announcement_view: false,
          announcement_add: false,
          announcement_edit: false,
          announcement_delete: false,
          announcement_all: false,
        },
        subUrl: '/portal/pengumuman',
        order: '004',
      },
    ],
  },
  {
    id: 'akademik',
    icon: 'school',
    title: 'Akademik',
    alias: 'akademik',
    auth: { akademik_view: false },
    url: '/akademik',
    order: '009',
    subm: [
      {
        id: 'profilsekolah',
        m: 'akademik',
        submenuTitle: 'Profil Sekolah',
        alias: 'akademik/profilsekolah',
        auth: {
          profilsekolah_view: false,
          profilsekolah_edit: false,
        },
        subUrl: '/akademik/profilsekolah',
        order: '001',
      },
      {
        id: 'jenjang',
        m: 'akademik',
        submenuTitle: 'Jenjang',
        alias: 'akademik/jenjang',
        auth: {
          jenjang_view: false,
          jenjang_add: false,
          jenjang_edit: false,
          jenjang_status: false,
          jenjang_delete: false,
          jenjang_sync: false,
        },
        subUrl: '/akademik/jenjang',
        order: '002',
      },
      {
        id: 'tingkat',
        m: 'akademik',
        submenuTitle: 'Tingkat',
        alias: 'akademik/tingkat',
        auth: {
          tingkat_view: false,
          tingkat_add: false,
          tingkat_edit: false,
          tingkat_status: false,
          tingkat_delete: false,
          tingkat_sync: false,
        },
        subUrl: '/akademik/tingkat',
        order: '003',
      },
      {
        id: 'kelas',
        m: 'akademik',
        submenuTitle: 'Kelas',
        alias: 'akademik/kelas',
        auth: {
          kelas_view: false,
          kelas_add: false,
          kelas_edit: false,
          kelas_status: false,
          kelas_delete: false,
          kelas_sync: false,
        },
        subUrl: '/akademik/kelas',
        order: '004',
      },
      {
        id: 'pelajaran',
        m: 'akademik',
        submenuTitle: 'Pelajaran',
        alias: 'akademik/pelajaran',
        auth: {
          pelajaran_view: false,
          pelajaran_add: false,
          pelajaran_edit: false,
          pelajaran_status: false,
          pelajaran_sync: false,
          pelajaran_import: false,
          pelajaran_delete: false,
        },
        subUrl: '/akademik/pelajaran',
        order: '005',
      },
      {
        id: 'year',
        m: 'akademik',
        submenuTitle: 'Tahun',
        alias: 'akademik/year',
        auth: {
          year_view: false,
          year_add: false,
          year_edit: false,
          year_status: false,
          year_delete: false,
        },
        subUrl: '/akademik/year',
        order: '006',
      },
      {
        id: 'sem',
        m: 'akademik',
        submenuTitle: 'Semester',
        alias: 'akademik/sem',
        auth: {
          sem_view: false,
          sem_add: false,
          sem_edit: false,
          sem_delete: false,
        },
        subUrl: '/akademik/sem',
        order: '007',
      },
    ],
  },
  {
    id: 'setting',
    icon: 'account-cog',
    title: 'Setting',
    alias: 'setting',
    auth: { setting_view: false },
    url: '/setting',
    order: '010',
    subm: [
      {
        id: 'user',
        m: 'setting',
        submenuTitle: 'User',
        alias: 'setting/user',
        auth: {
          user_view: false,
          user_add: false,
          user_edit: false,
          user_status: false,
          user_delete: false,
          user_all: false,
        },
        subUrl: '/setting/user',
        order: '001',
      },
      {
        id: 'role',
        m: 'setting',
        submenuTitle: 'Role',
        alias: 'setting/role',
        auth: {
          role_view: false,
          role_add: false,
          role_edit: false,
          role_delete: false,
        },
        subUrl: '/setting/role',
        order: '002',
      },
      // {
      //   id: 'appsetting',
      //   m: 'setting',
      //   submenuTitle: 'Aplikasi',
      //   alias: 'setting/app-setting',
      //   auth: {
      //     appsetting_view: false,
      //     appsetting_add: false,
      //     appsetting_edit: false,
      //     appsetting_delete: false,
      //   },
      //   subUrl: '/setting/app-setting',
      //   order: '003',
      // },
    ],
  },
];
