import React, { Component } from 'react';
import { connect } from 'react-redux';
import { resetPassword } from './redux/reduxApi';
import { closeModal } from '../../app/modals/redux/modalActions';
import MResetPasswordForm from './MResetPasswordForm';
import Modal from '../../ui/Modal';

const actions = {
  closeModal,
  resetPassword,
};

class ModalResetPassword extends Component {
  render() {
    const { data, closeModal, resetPassword } = this.props;
    const auth = data.auth;
    const history = data.history;
    const item = data.item;
    if (item.roles === 'SA') {
      closeModal();
    }
    return (
      <Modal closeModal={closeModal}>
        <MResetPasswordForm
          auth={auth}
          history={history}
          initialValues={item}
          resetPassword={resetPassword}
          closeModal={closeModal}
        />
      </Modal>
    );
  }
}

export default connect(null, actions)(ModalResetPassword);
