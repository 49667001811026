import React from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import MViewSummaryAnswerForm from './MViewSummaryAnswerForm';
import { closeModal } from '../../../app/modals/redux/modalActions';
import { submitTugasScores } from './redux/reduxApi';

const mapState = (state, ownProps) => {
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities;
  }
  return {
    loading: state.async.loading,
    scope: scope,
  };
};

const actions = {
  closeModal,
  submitTugasScores,
};

function ModalViewSummaryAnswer(props) {
  const { loading, scope, data, submitTugasScores, closeModal } = props;
  const history = useHistory();
  const auth = data && data.auth;
  const id = data && data.id;
  const studentDetail = data && data.item;
  const answers =
    data && data.item && data.item.answers && JSON.parse(data.item.answers);
  const questions = data && data.questions;
  const keyAnswers = data && data.keyAnswers;
  const stateScores = data && data.stateScores;
  const scores = data && data.scores;
  const optScore = data && data.optScore;
  let selectedScore =
    scores &&
    scores.find((i) => i.studentTugasId + '' === studentDetail.id + '');
  let finalScore;
  let detailScore;
  if (!selectedScore) {
    let selectedStateScore = stateScores.find(
      (i) => i.studentTugasId + '' === studentDetail.id + ''
    );
    finalScore = selectedStateScore?.finalScore;
    detailScore = selectedStateScore?.detailScore;
  } else {
    finalScore = selectedScore?.finalScore;
    detailScore = selectedScore?.detailScore;
  }

  const onFormSubmit = async (values) => {
    console.log('submit form');
  };

  return (
    <div className='modal is-active'>
      <div className='modal-background' onClick={closeModal}></div>
      <div className='modal-card'>
        <section className='modal-card-body' style={{borderRadius: 5}}>
          <MViewSummaryAnswerForm
            auth={auth}
            history={history}
            scope={scope}
            loading={loading}
            closeModal={closeModal}
            id={id}
            studentDetail={studentDetail}
            initialValues={{}}
            questions={questions}
            keyAnswers={keyAnswers}
            answers={answers}
            scores={scores}
            optScore={optScore}
            finalScore={finalScore}
            detailScore={detailScore}
            submitTugasScores={submitTugasScores}
            onFormSubmit={onFormSubmit}
          />
        </section>
      </div>
    </div>
  );
}

export default withRouter(connect(mapState, actions)(ModalViewSummaryAnswer));
