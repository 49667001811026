import React from 'react';
import { closeModal } from '../../../app/modals/redux/modalActions';
import { connect } from 'react-redux';
import { editRemarksMonitoring } from './redux/reduxApi';
import { toastr } from 'react-redux-toastr';
import Icon from '@mdi/react';
import { mdiCancel, mdiCheckCircleOutline } from '@mdi/js';

function mapState(state) {
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities;
  }
  return {
    scope: scope,
  };
}

const actions = {
  editRemarksMonitoring,
  closeModal,
};

function ModalPesanView({ closeModal, editRemarksMonitoring, data }) {
  const { auth, history, id, tes, student, block, profilePic } = data;

  const handleBlock = () => {
    let remarks = tes?.remarks ? JSON.parse(tes.remarks) : {};
    let blocks = remarks?.blocks ? remarks.blocks : [];
    let newBlock = {
      nis: student.nis,
      studentName: student.studentName,
      createdAt: new Date(),
      createdBy: auth.username,
    };
    if (blocks && blocks[0]) {
      if (block) {
        blocks = [...blocks.filter((b) => b.nis !== student.nis)];
      } else {
        blocks = [...blocks.filter((b) => b.nis !== student.nis), newBlock];
      }
    } else {
      blocks.push(newBlock);
    }
    let updateRemarks = { ...remarks, blocks };
    let newTes = { ...tes, remarks: JSON.stringify(updateRemarks) };
    editRemarksMonitoring(auth, history, newTes, id);
    toastr.success('Info', `${block ? 'un-' : ''}Block siswa berhasil`);
    closeModal();
  };

  return (
    <div className='modal is-active'>
      <div className='modal-background' onClick={closeModal}></div>
      <div
        className='modal-content flex justify-center'
        style={{ overflow: 'hidden' }}
      >
        <div className='' style={{ width: 600 }}>
          <div className='' style={{ overflow: 'hidden' }}>
            <div className='card'>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingTop: 8,
                  paddingRight: 8,
                  overflow: 'hidden',
                }}
              >
                <button
                  onClick={closeModal}
                  className='delete'
                  aria-label='close'
                ></button>
              </div>
              <div
                className='card-content'
                style={{ padding: '0px 17px 20px 15px' }}
              >
                <div className='media' style={{ alignItems: 'center' }}>
                  <div className='media-left'>
                    <figure className='image is-48x48'>
                      <img
                        src={profilePic}
                        alt='profile'
                        style={{ height: 48, width: 48 }}
                      />
                    </figure>
                  </div>
                  <div className='media-content'>
                    <p className='has-text-grey-dark has-text-weight-semibold is-size-6'>
                      {student.siswa}
                    </p>
                    <p className='has-text-grey is-size-7'>{student.nis}</p>
                  </div>
                </div>
                <div className='content' style={{ marginTop: -8 }}>
                  <div className='flex justify-center'>
                    <div className='flex justify-start'>
                      <div className='is-size-5'>
                        Apakah anda yakin ingin {block ? 'un' : ''}block siswa?{' '}
                        <br />
                        <span className='is-italic is-size-7'>
                          Note: siswa yang di-
                          {block
                            ? 'unblock bisa melanjutkan '
                            : 'block tidak bisa melanjutkan '}
                          ujian.
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='has-text-grey-dark is-size-7 flex justify-between mt-2'>
                    <div className='mb-2 is-italic'></div>
                    <button
                      onClick={() => handleBlock()}
                      className={`button is-small is-rounded is-outlined is-${
                        block ? 'info' : 'danger'
                      }`}
                    >
                      <span className='icon is-small'>
                        <Icon
                          path={block ? mdiCheckCircleOutline : mdiCancel}
                        />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(mapState, actions)(ModalPesanView);
