import React, { useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  getCalendarYears,
  getCalendars,
  statusCalendar,
  deleteCalendar,
} from './redux/reduxApi';
import { closeModal } from '../../../app/modals/redux/modalActions';
import Modal from '../../../ui/Modal';
import Button from '../../../ui/Button';
import Icon from '@mdi/react';
import { mdiMenuRight } from '@mdi/js';

const mapState = (state, ownProps) => {
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities;
  }
  let years = [];
  if (state.calendars && state.calendars.items && state.calendars.items[0]) {
    years = state.calendars.years;
  }
  return {
    loading: state.async.loading,
    auth: state.auth,
    scope: scope,
    calendars: state.calendars,
    years: years,
  };
};

const actions = {
  getCalendars,
  getCalendarYears,
  statusCalendar,
  deleteCalendar,
  closeModal,
};

function ModalSetActive(props) {
  const {
    auth,
    history,
    scope,
    calendars,
    years,
    statusCalendar,
    getCalendars,
    getCalendarYears,
    deleteCalendar,
    closeModal,
  } = props;
  const [state, setState] = useState({
    title: 'Kalender',
    itemPerPage: 10,
    currentPage: 1,
    searchText: '',
    isActive: false,
    calendars: {},
  });

  useEffect(() => {
    setState({
      title: 'Kalender',
      itemPerPage: 10,
      currentPage: 1,
      searchText: '',
      isActive: false,
    });
    getCalendars(auth, history);
    getCalendarYears(auth, history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setState({
      ...state,
      calendars: calendars,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendars]);

  // Set Active Calendar
  const handleStatusCalendar = (e, value) => {
    e.preventDefault();
    if (value.status) {
      return;
    }
    let newValue = {
      ...value,
      status: !value.status,
    };
    statusCalendar(auth, history, newValue);
  };

  const handleEditCalendar = (calendar) => {
    history.push({
      pathname: '/calendar/edit/' + calendar.id,
      state: { detail: calendar },
    });
    closeModal();
  };

  const handleDeleteCalendar = (calendar) => {
    deleteCalendar(auth, history, calendar.id);
  };

  const List = (props) => {
    const {
      scope,
      items,
      title,
      currentPage,
      itemPerPage,
      onStatus,
      onEdit,
      onDelete,
    } = props;
    return (
      <>
        {items &&
          items.length !== 0 &&
          items.map((item, index) => (
            <ListItems
              key={item.id}
              index={index}
              item={item}
              currentPage={currentPage}
              itemPerPage={itemPerPage}
              scope={scope}
              onStatus={onStatus}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          ))}
        {items && items.length === 0 && (
          <tr key={0}>
            <td colSpan='7'>
              Tidak ada <span className='is-lowercase'>{title}</span>
            </td>
          </tr>
        )}
      </>
    );
  };

  const ListItems = (props) => {
    const {
      item,
      currentPage,
      itemPerPage,
      index,
      scope,
      onStatus,
      onEdit,
      onDelete,
    } = props;
    const scopeDetail = scope && scope.m;

    const page = (currentPage - 1) * itemPerPage;
    return (
      <tr>
        <td className='has-text-centered'>{page + index + 1}</td>
        <td>{item.title}</td>
        <td className='has-text-centered'>{item.year}</td>
        <td style={{ width: 100 }}>
          <div className='content-wrap flex justify-center'>
            <div>
              {scope.m['calendar_edit'] && (
                <div className='control'>
                  {scopeDetail['calendar_active'] && (
                    <div
                      className={`tags has-addons${
                        item.status === true ? '' : ' hand-pointer'
                      }`}
                      onClick={(e) => onStatus(e, item)}
                    >
                      <span className='tag is-dark'>
                        {item.status ? '' : 'No'}
                      </span>
                      <span className='tag is-info'>
                        {item.status ? 'Aktif' : ''}
                      </span>
                    </div>
                  )}
                  {!scopeDetail['calendar_active'] && (
                    <div className='tags has-addons'>
                      <span className='tag is-dark'>
                        {item.status ? '' : 'No'}
                      </span>
                      <span className='tag is-info'>
                        {item.status ? 'Aktif' : ''}
                      </span>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </td>
        <td className='has-text-centered'>
          <div className='buttons middle flex justify-center'>
            {scopeDetail['calendar_edit'] && (
              <Button variant='edit' onClick={() => onEdit(item)} />
            )}
            {scopeDetail['calendar_delete'] && (
              <Button
                variant='delete'
                disabled={item.status ? true : false}
                onClick={() => onDelete(item)}
              />
            )}
          </div>
        </td>
      </tr>
    );
  };

  return (
    <Modal variant='info' label='noLabel' closeModal={closeModal}>
      <div className='columns'>
        <div className='column'>
          <div className='level'>
            <div className='level-left'>
              <div className='level-item'>
                <nav className='breadcrumb is-size-6' aria-label='breadcrumbs'>
                  <ul>
                    <span className='flex align-center'>
                      <Link className='menu-top' to={'/calendar'}>
                        Calendar
                      </Link>
                      <span className='icon is-small mr-1'>
                        <Icon path={mdiMenuRight} />
                      </span>
                      Set Aktif
                    </span>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div className='table-container'>
            <div className='c-table'>
              <table
                id='fadein'
                className='table is-bordered is-fullwidth is-hoverable is-narrow'
              >
                <thead>
                  <tr>
                    <th className='has-text-centered'>No</th>
                    <th>Judul Kalender</th>
                    <th className='has-text-centered'>Tahun Ajaran</th>
                    <th className='has-text-centered'>Status</th>
                    <th className='has-text-centered'>Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  <List
                    auth={auth}
                    scope={scope}
                    items={state && state.calendars && state.calendars.items}
                    years={years}
                    title={state.title}
                    currentPage={state && state.currentPage}
                    itemPerPage={state && state.itemPerPage}
                    onStatus={handleStatusCalendar}
                    onEdit={handleEditCalendar}
                    onDelete={handleDeleteCalendar}
                  />
                  <tr>
                    <td colSpan={5}>
                      <div className='control my-2'>
                        <div className='tags has-addons'>
                          <span className='tag is-info is-rounded'>Total</span>
                          <span className='tag is-rounded'>
                            {calendars.items.length}
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default withRouter(connect(mapState, actions)(ModalSetActive));
