import React from 'react';
import { connect } from 'react-redux';
import { closeModal } from '../../../app/modals/redux/modalActions';
import Modal from '../../../ui/Modal';
import Button from '../../../ui/Button';

const actions = {
  closeModal,
};

function ModalInfoAdd({ closeModal }) {
  return (
    <Modal variant='info' label='Informasi' closeModal={closeModal}>
      <div
        className='content pl-2 pt-4 overflow-auto'
        style={{ maxHeight: 600 }}
      >
        <h5>Langkah-Langkah:</h5>
        <div className='preline'>
          <ol>
            <li>Pilih siswa yang akan dikirim pesan.</li>
            <li>
              Tuliskan pesan yang akan dikirim. Jika pesan menggunakan variabel, maka tambahkan [Angka] yang akan
              digantikan menjadi isian, maka ketikkan pada isi pesan seperti
              contoh ini:
              <br />
              <blockquote>
                Diberitahukan kepada orang tua [1] untuk segera melunasi SPP
                bulan [2], sebesar Rp. [3].
                <br />
                Harap segera menghubungi admin sekolah untuk detail pembayaran.
              </blockquote>
            </li>
            <li>
              Klik <span className='tag is-info mb-1'>+ variabel</span>, dan
              kemudian tambahkan isian sesuai dengan banyaknya variabel yang
              dibuat di isi pesan, dalam hal ini terdapat 3 isian variabel,
              setiap variabel dipisahkan dengan tanda titik koma ";". <br />
              <blockquote>
                Andi
                <br />
                Andi;Juli;100.000
              </blockquote>
            </li>
          </ol>
          <ol>
            Kli pada{' '}
            <span className='tag is-info'>
              <i className='mdi mdi-file-search icon'></i>
            </span>{' '}
            untuk melihat preview pesan, jika preview sudah sesuai klik{' '}
            <span className='tag is-info'>
              <i className='mdi mdi-content-save icon'></i>
            </span>{' '}
            untuk menyimpan
          </ol>
        </div>
      </div>
      <div className='flex justify-end align-center pt-2'>
        <div className='buttons'>
          <Button cvariant='cback' onClick={closeModal} />
        </div>
      </div>
    </Modal>
  );
}

export default connect(null, actions)(ModalInfoAdd);
