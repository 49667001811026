import React from 'react';
import { connect } from 'react-redux';
import { deleteRole } from './redux/reduxApi';
import { closeModal } from '../../app/modals/redux/modalActions';
import Modal from '../../ui/Modal';
import Button from '../../ui/Button';

const actions = {
  closeModal,
  deleteRole,
};

function ModalDeleteItem({data, deleteRole, closeModal}) {
  const { auth, history, item } = data;

  function onDelete() {
    deleteRole(auth, history, item.id);
    closeModal();
  }

  return (
    <Modal closeModal={closeModal} variant='danger'>
      <p className='my-3 mx-1'>
        Apakah Anda yakin ingin menghapus role{' '}
        <span className='has-text-danger has-text-weight-semibold is-capitalized'>{`${item?.title}`}</span>{' '}
        ?
      </p>
      <div className='flex justify-end align-center pt-2'>
        <div className='buttons'>
          <Button cvariant='cdelete' onClick={() => onDelete()} />
          <Button cvariant='cback' onClick={closeModal} />
        </div>
      </div>
    </Modal>
  );
}

export default connect(null, actions)(ModalDeleteItem);
