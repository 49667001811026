import React from 'react';
import { connect } from 'react-redux';
import { deleteUser } from './redux/reduxApi';
import { closeModal } from '../../app/modals/redux/modalActions';
import Modal from '../../ui/Modal';
import Button from '../../ui/Button';

const actions = {
  closeModal,
  deleteUser,
};

function ModalDeleteItem({ data, closeModal, deleteUser }) {
  const { auth, history, item } = data;

  function onDelete() {
    deleteUser(auth, history, item.id);
    history.push('./setting/user');
    closeModal();
  }

  return (
    <Modal variant='danger' closeModal={closeModal}>
        <p className='py-4 my-2 mx-2'>
          Apakah Anda yakin ingin menghapus user{' '}
          <span className='has-text-danger has-text-weight-semibold is-capitalized'>{`${item.username}`}</span>{' '}
          ?
        </p>
        <div className='flex justify-end align-center pt-2'>
          <div className='buttons'>
            <Button
              cvariant='cdelete'
              withLabel={true}
              onClick={() => onDelete()}
            />
            <Button cvariant='cback' withLabel={true} onClick={closeModal} />
          </div>
        </div>
    </Modal>
  );
}

export default connect(null, actions)(ModalDeleteItem);
