import React, { useEffect, useState } from 'react';
import { closeModal } from '../../../app/modals/redux/modalActions';
import { connect } from 'react-redux';
import TextInput from '../../../app/common/form/TextInput';
import TextArea from '../../../app/common/form/TextArea';
// import DateInput from '../../../app/common/form/DateInput';
import { reduxForm, Field } from 'redux-form';
import {
  composeValidators,
  combineValidators,
  isRequired,
  hasLengthGreaterThan,
} from 'revalidate';
import Modal from '../../../ui/Modal';
import Icon from '@mdi/react';
import { mdiMenuRight } from '@mdi/js';

function mapState(state) {
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities;
  }
  return {
    auth: state.auth,
    scope: scope,
    loading: state.async.loading,
  };
}

const actions = {
  closeModal,
};

const validate = combineValidators({
  name: composeValidators(
    isRequired({ message: 'Nama perusahaan harus diisi' }),
    hasLengthGreaterThan(1)({
      message: 'Nama Perusahaan harus memiliki paling sedikit 2 karakter',
    })
  )(),
});

function ModalViewEvent(props) {
  const { data, closeModal, handleSubmit } = props;
  const [definput, setDefinput] = useState({});

  useEffect(() => {
    let event = data && data.item && data.item.id ? data.item : null;
    if (event) {
      setDefinput({ ...definput, ...event });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeName = (e) => {};

  const onFormSubmit = (values) => {};

  return (
    <Modal variant='info' label='noLabel' closeModal={closeModal}>
      <div className='columns'>
        <div className='column'>
          <div className='level'>
            <div className='level-left'>
              <div className='level-item'>
                <nav className='breadcrumb is-size-6' aria-label='breadcrumbs'>
                  <ul>
                    <span className='flex align-center has-text-info'>
                      Calendar
                      <span className='icon is-small mr-1'>
                        <Icon path={mdiMenuRight} />
                      </span>
                      Detail
                    </span>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit(onFormSubmit)} autoComplete='off'>
            <div className='field is-horizontal'>
              <div className='field-body'>
                <Field
                  label='Judul Event'
                  name='subject'
                  type='text'
                  component={TextInput}
                  placeholder='Judul Event'
                  defaultValue={definput.subject}
                  onChange={(e) => onChangeName(e)}
                  readOnly={true}
                />
                <Field
                  label='Tipe'
                  name='type'
                  type='text'
                  component={TextInput}
                  placeholder='Tipe'
                  fullwidth={true}
                  defaultValue={definput.type}
                  onChange={(e) => onChangeName(e)}
                  readOnly={true}
                />
              </div>
            </div>
            {definput.allDayEvent && (
              <Field
                label='All Day Event'
                name='allDayEvent'
                type='text'
                component={TextInput}
                placeholder='All Day Event'
                fullwidth={true}
                defaultValue={definput.allDayEvent === true ? 'Yes' : 'No'}
                onChange={(e) => onChangeName(e)}
                readOnly={true}
              />
            )}
            {definput.allDayEvent && definput.type === 'event' && (
              <>
                <Field
                  label={!definput.endDate ? 'Tanggal' : 'Mulai'}
                  name='dateStart'
                  type='text'
                  component={TextInput}
                  placeholder={!definput.endDate ? 'Tanggal' : 'Mulai'}
                  fullwidth={true}
                  defaultValue={definput.startDate}
                  onChange={(e) => onChangeName(e)}
                  readOnly={true}
                />
                {definput.endDate && (
                  <Field
                    label='Berakhir'
                    name='dateEnd'
                    type='text'
                    component={TextInput}
                    placeholder='Berakhir'
                    fullwidth={true}
                    defaultValue={definput.endDate}
                    onChange={(e) => onChangeName(e)}
                    readOnly={true}
                  />
                )}
              </>
            )}
            {!definput.allDayEvent && (
              <div className='field is-horizontal'>
                <div className='field-body'>
                  <Field
                    label='Mulai'
                    name='dateStart'
                    type='text'
                    component={TextInput}
                    placeholder='Mulai'
                    fullwidth={true}
                    defaultValue={definput.startDate + ' ' + definput.startTime}
                    onChange={(e) => onChangeName(e)}
                    readOnly={true}
                  />
                  <Field
                    label='Berakhir'
                    name='dateEnd'
                    type='text'
                    component={TextInput}
                    placeholder='Berakhir'
                    fullwidth={true}
                    defaultValue={definput.endDate + ' ' + definput.endTime}
                    onChange={(e) => onChangeName(e)}
                    readOnly={true}
                  />
                </div>
              </div>
            )}
            {definput.type === 'libur' && (
              <div className='field is-horizontal'>
                <div className='field-body'>
                  <Field
                    label={!definput.endDate ? 'Tanggal' : 'Mulai'}
                    name='dateStart'
                    type='text'
                    component={TextInput}
                    placeholder={!definput.endDate ? 'Tanggal' : 'Mulai'}
                    fullwidth={true}
                    defaultValue={definput.startDate}
                    onChange={(e) => onChangeName(e)}
                    readOnly={true}
                  />
                  {definput.endDate && (
                    <Field
                      label='Berakhir'
                      name='dateEnd'
                      type='text'
                      component={TextInput}
                      placeholder='Berakhir'
                      fullwidth={true}
                      defaultValue={definput.endDate}
                      onChange={(e) => onChangeName(e)}
                      readOnly={true}
                    />
                  )}
                </div>
              </div>
            )}
            <Field
              label='Deskripsi'
              name='description'
              type='text'
              component={TextArea}
              placeholder='Deskripsi'
              fullwidth={true}
              readOnly={true}
            />
          </form>
        </div>
      </div>
    </Modal>
  );
}

export default connect(
  mapState,
  actions
)(reduxForm({ form: 'calendarViewEventModal', validate })(ModalViewEvent));
