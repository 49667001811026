import React from 'react';
import { connect } from 'react-redux';
import { deleteSem } from './redux/reduxApi';
import { closeModal } from '../../../app/modals/redux/modalActions';
import Modal from '../../../ui/Modal';
import Icon from '@mdi/react';
import { mdiShieldHalfFull } from '@mdi/js';
import Button from '../../../ui/Button';

const actions = {
  closeModal,
  deleteSem,
};

function ModalDeleteItem({ data, closeModal, deleteSem }) {
  const { auth, history, item } = data;

  function onDelete() {
    deleteSem(auth, history, item.id);
    history.push('./akademik/sem');
    closeModal();
  }

  return (
    <Modal closeModal={closeModal}>
      <div className='px-5'>
        <p className='has-text-info flex justify-start align-center is-size-5'>
          <span className='icon is-medium'>
            <Icon path={mdiShieldHalfFull} size={1} />
          </span>
          Konfirmasi
        </p>
        <p className='py-4 my-2 mx-2'>
          Apakah Anda yakin ingin menghapus semester{' '}
          <span className='has-text-danger has-text-weight-semibold'>{`${item.title}`}</span>{' '}
          ?
        </p>
        <div className='flex justify-end align-center pt-2'>
          <div className='buttons'>
            <Button
              cvariant='cdelete'
              withLabel={true}
              onClick={() => onDelete()}
            />
            <Button cvariant='cback' withLabel={true} onClick={closeModal} />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default connect(null, actions)(ModalDeleteItem);
