import {
  asyncActionFinish,
  asyncActionError,
} from '../../../../app/common/reduxs/async/asyncActions';
import {
  rppsGet,
  rppAdd,
  rppGet,
  rppEdit,
  rppUploadFile,
  rppDelete,
  rppResetSearch,
} from './reduxAction';
import { ASYNC_ACTION_START } from '../../../../app/common/reduxs/async/asyncConstant';
import { checkErr } from '../../../../app/common/helpers/checkRes';
import { fetchFunc } from '../../../../app/common/helpers/fetchHelpers';
import { logsFunc } from '../../../../app/common/helpers/othersHelpers';
import { SITE_ADDRESS } from '../../../../app/common/data/siteConfig';
import { saveAs } from 'file-saver';
import { toastr } from 'react-redux-toastr';

export const getRpps = (
  auth,
  history,
  itemPerPage,
  currentPage,
  searchText,
  searchMoreText,
  isReset
) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getRpps' });
    const formData = new FormData();
    if (isReset === 'reset') {
      dispatch(rppResetSearch());
    }
    const allDataAccess = auth.authorities.subm.rpp_all;
    formData.append('itemPerPage', itemPerPage ? itemPerPage : 10);
    formData.append('currentPage', currentPage);
    formData.append('searchText', searchText);
    formData.append('allData', allDataAccess);
    formData.append('searchMoreText', JSON.stringify(searchMoreText));
    try {
      const res = await fetchFunc(
        'gets',
        auth,
        history,
        'rpp/',
        formData,
        dispatch
      );
      const items = res.items;
      const total = res.total;
      const search = {
        currentPage,
        searchText,
        itemPerPage: itemPerPage ? itemPerPage : 10,
        ...searchMoreText,
      };
      dispatch(rppsGet({ total, items, search }));
      if (isReset === 'reset') {
        history.push('/rpp');
      }
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const addRpp = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'addRpp' });
    let logs = logsFunc('create', auth.userid, auth.username, null);
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'rpp/add/' + values.id,
        formData,
        dispatch
      );
      const rpp = res.rpp;
      if (rpp) {
        dispatch(rppAdd(rpp));
        if (values.addMedia) {
          history.push('/rpp/edit/' + rpp.id);
        } else {
          toastr.success('Success', 'RPP berhasil disimpan.');
          history.push('/rpp/' + rpp.id);
        }
      }
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getRpp = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getRpp' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'rpp/detail/' + id,
        formData,
        dispatch
      );
      const rpp = res.rpp;
      dispatch(rppGet(rpp));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const editRpp = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'editRpp' });
    let logs = logsFunc('edit', auth.userid, auth.username, values.logs);
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'rpp/edit/' + values.id,
        formData,
        dispatch
      );
      const rpp = res.rpp;
      dispatch(rppEdit(rpp));
      history.push('/rpp/' + values.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const publishRpp = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'publishRpp' });
    let logs = logsFunc('edit', auth.userid, auth.username, values.logs);
    const formData = new FormData();
    formData.append('published', values.published);
    formData.append('year', values.year);
    formData.append('sem', values.sem);
    formData.append('jenjang', values.jenjang);
    formData.append('tingkat', values.tingkat);
    formData.append('kelas', values.kelas.toString());
    formData.append('pelajaran', values.pelajaran);
    formData.append('tags', JSON.stringify(values.tags));
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'rpp/publish/' + values.id,
        formData,
        dispatch
      );
      const rpp = res.rpp;
      dispatch(rppEdit(rpp));
      history.push('/rpp/' + values.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const uploadAttachment = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'uploadAttachment' });
    dispatch(rppUploadFile('start'));
    let rppId = values.id;
    let logs = logsFunc('edit', auth.userid, auth.username, values.logs);
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append(
      'attachments',
      values.attachments ? JSON.stringify(values.attachments) : ''
    );
    formData.append('file', values.file);
    formData.append('key', values.key);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'uploadAttach',
        auth,
        history,
        'rpp/upload-attachment/' + rppId,
        formData,
        dispatch
      );
      const rpp = res.rpp;
      dispatch(rppEdit(rpp));
      dispatch(rppUploadFile('finish'));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const uploadImage = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'uploadImage' });
    const formData = new FormData();
    formData.append('id', values.id);
    formData.append('file', values.file);
    formData.append('filename', values.filename);
    try {
      const res = await fetchFunc(
        'uploadImage',
        auth,
        history,
        'rpp/upload-image/' + values.id,
        formData,
        dispatch
      );
      const url = res.url;
      dispatch(asyncActionFinish());
      return url;
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const downloadFile = (auth, history, data, file) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'downloadFile' });
    const formData = new FormData();
    formData.append('userId', auth.userId);
    formData.append('username', auth.username);
    formData.append('filelink', file.filelink);
    try {
      const fetchData = await fetch(
        SITE_ADDRESS + 'rpp/download-file/' + data.id,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const blob = await fetchData.blob();
      saveAs(
        blob,
        file.filelink.split('/')[file.filelink.split('/').length - 1]
      );
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deleteFile = (auth, history, data, file) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deleteFile' });
    let logs = logsFunc('edit', auth.userid, auth.username, data.logs);
    const formData = new FormData();
    formData.append('filelink', file.filelink);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'deleteFile',
        auth,
        history,
        'rpp/delete-file/' + data.id,
        formData,
        dispatch
      );
      const rpp = res.rpp;
      dispatch(rppEdit(rpp));
      history.push('/rpp/edit/' + data.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deleteRpp = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deleteRpp' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'delete',
        auth,
        history,
        'rpp/delete/' + id,
        formData,
        dispatch
      );
      const rpp = res.rpp;
      dispatch(rppDelete(rpp));
      history.push('/rpp');
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};
