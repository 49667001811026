import React from 'react';
import { connect } from 'react-redux';
import { deleteAllPortalusers } from './redux/reduxApi';
import { closeModal } from '../../../app/modals/redux/modalActions';
import Modal from '../../../ui/Modal';
import Button from '../../../ui/Button';

const actions = {
  closeModal,
  deleteAllPortalusers,
};

function ModalDeleteAllPortalusers({ data, deleteAllPortalusers, closeModal }) {
  const { auth, history } = data;

  function handleDelete() {
    deleteAllPortalusers(auth, history);
    closeModal();
  }

  return (
    <Modal variant='danger' closeModal={closeModal}>
      <p className='is-size-5 has-text-centered mb-5 mt-5'>
        Apakah Anda yakin ingin menghapus semua portal user ?
      </p>
      <div className='flex flex-column justify-start mx-5 px-1 mb-4'>
        <p className='is-size-6 is-italic mb-2'>
          <span className='tag is-info is-rounded'>Note:</span>
        </p>
        <p className='is-italic'>
          Jika sync portal user gagal dikarenakan duplikat data, maka bisa
          dilakukan delete semua portal user, kemudian lakukan sync kembali
        </p>
      </div>
      <div className='flex justify-end align-center pt-2'>
        <div className='buttons'>
          <Button cvariant='cdelete' onClick={handleDelete} />
          <Button cvariant='cback' onClick={closeModal} />
        </div>
      </div>
    </Modal>
  );
}

export default connect(null, actions)(ModalDeleteAllPortalusers);
