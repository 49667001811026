import React from 'react';
import { connect } from 'react-redux';
import { deletePelajaran } from './redux/reduxApi';
import { closeModal } from '../../../app/modals/redux/modalActions';
import Modal from '../../../ui/Modal';
import Button from '../../../ui/Button';

const actions = {
  closeModal,
  deletePelajaran,
};

function ModalDeleteItem({ data, deletePelajaran, closeModal }) {
  const { auth, history, item } = data;
  function handleDelete() {
    deletePelajaran(auth, history, item.id);
    closeModal();
  }

  return (
    <Modal variant='danger' closeModal={closeModal}>
      <p className='my-3 mx-1'>
        Apakah Anda yakin ingin menghapus guru{' '}
        <span className='has-text-danger has-text-weight-semibold'>{`${item.title}`}</span>{' '}
        ?
      </p>
      <div className='flex justify-end align-center pt-2'>
        <div className='buttons'>
          <Button cvariant='cdelete' onClick={handleDelete} />
          <Button cvariant='cback' onClick={closeModal} />
        </div>
      </div>
    </Modal>
  );
}

export default connect(null, actions)(ModalDeleteItem);
