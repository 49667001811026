import React from 'react';
import { closeModal } from '../../../app/modals/redux/modalActions';
import { connect } from 'react-redux';
import { editCalendar } from './redux/reduxApi';
import Modal from '../../../ui/Modal';
import Button from '../../../ui/Button';

function mapState(state) {
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities;
  }
  return {
    auth: state.auth,
    scope: scope,
    loading: state.async.loading,
  };
}

const actions = {
  closeModal,
  editCalendar,
};

function ModalDeleteEvent(props) {
  const { data, closeModal, editCalendar } = props;
  const auth = data.auth;
  const history = data.history;
  const item = data.item;
  const calendar = data.calendar;

  function handleDelete() {
    let events = calendar.events;
    let newEvents = events.filter((i) => i.id !== item.id);
    let newCalendar = { ...calendar, events: newEvents };
    editCalendar(auth, history, newCalendar);
    closeModal();
  }

  return (
    <Modal variant='info' closeModal={closeModal}>
      <p className='my-3 mx-1'>
        Apakah Anda yakin ingin menghapus event{' '}
        <span className='has-text-danger has-text-weight-semibold is-capitalized'>{`${item?.subject}`}</span>
        ?
      </p>
      <div className='flex justify-end align-center pt-2'>
        <div className='buttons'>
          <Button cvariant='csave' onClick={handleDelete} />
          <Button cvariant='cback' onClick={closeModal} />
        </div>
      </div>
    </Modal>
  );
}

export default connect(mapState, actions)(ModalDeleteEvent);
