import React from 'react';
import { connect } from 'react-redux';
import { deleteMateri } from './redux/reduxApi';
import { closeModal } from '../../../app/modals/redux/modalActions';
import Button from '../../../ui/Button';
import Modal from '../../../ui/Modal';

const actions = {
  closeModal,
  deleteMateri,
};

function ModalDeleteItem({ data, closeModal, deleteMateri }) {
  const { auth, history, item } = data;

  function handleDelete() {
    deleteMateri(auth, history, item.id);
    closeModal();
  }

  return (
    <Modal variant='danger' closeModal={closeModal}>
      <p className='my-3 mx-1 text-wrap'>
        Apakah Anda yakin ingin menghapus materi{' '}
        <span className='ml-1 has-text-danger has-text-weight-semibold'>{`${item.title}`}</span>{' '}
        ?
      </p>
      <div className='flex justify-end align-center pt-2'>
        <div className='buttons'>
          <Button cvariant='cdelete' onClick={() => handleDelete()} />
          <Button cvariant='cback' onClick={closeModal} />
        </div>
      </div>
    </Modal>
  );
}

export default connect(null, actions)(ModalDeleteItem);
