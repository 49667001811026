import {
  asyncActionFinish,
  asyncActionError,
} from '../../../../app/common/reduxs/async/asyncActions';
import { ASYNC_ACTION_START } from '../../../../app/common/reduxs/async/asyncConstant';
import { checkErr } from '../../../../app/common/helpers/checkRes';
import { fetchFunc } from '../../../../app/common/helpers/fetchHelpers';
import {
  monitoringsGet,
  monitoringGet,
  monitoringStudentsGet,
  monitoringFilter,
} from './reduxAction';
import { logsFunc } from '../../../../app/common/helpers/othersHelpers';
import { toastr } from 'react-redux-toastr';

export const getMonitorings = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getMonitorings' });
    const formData = new FormData();
    formData.append('year', values.year);
    formData.append('jenjang', values.jenjang);
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'monitoring/',
        formData,
        dispatch
      );
      const tugass = res.tugass || [];
      const tess = res.tess || [];
      const totalTugass = res.totalTugass;
      const totalTess = res.totalTess;
      dispatch(monitoringsGet({ totalTugass, tugass, totalTess, tess }));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getMonitoring = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getMonitoring' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'monitoring/detail/' + id,
        formData,
        dispatch
      );
      const monitoring = res.monitoring;
      dispatch(monitoringGet(monitoring));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const editRemarksMonitoring = (auth, history, values, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'editRemarksMonitoring' });
    const formData = new FormData();
    formData.append('remarks', values.remarks);
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'monitoring/edit/remarks/' + id,
        formData,
        dispatch
      );
      const monitoring = res.monitoring;
      dispatch(monitoringGet(monitoring));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getMonitoringStudents = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getMonitoringStudent' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'monitoring/monitor-student/' + id,
        formData,
        dispatch
      );
      const monitorStudents = res.monitorStudents;
      dispatch(monitoringStudentsGet({ students: monitorStudents, id: id }));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const filterMonitoring = (val, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'filterMonitoring' });
    dispatch(monitoringFilter({ filter: val, id: id }));
    dispatch(asyncActionFinish());
  };
};

export const addMonitoring = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'addMonitoring' });
    let logs = logsFunc('add', auth.userid, auth.username);
    const formData = new FormData();
    formData.append('id', values.id);
    formData.append('title', values.title.trim());
    formData.append('year', values.year);
    formData.append('events', JSON.stringify(values.events));
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'add',
        auth,
        history,
        'monitoring/add/' + values.id,
        formData,
        dispatch
      );
      const monitoring = res.monitoring;
      dispatch(monitoringGet(monitoring));
      history.push({
        pathname: '/monitoring/edit/' + monitoring.id,
        state: { detail: monitoring },
      });
      toastr.success('Success', 'Data berhasil disimpan.');
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};
