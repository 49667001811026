import React from 'react';
import { connect } from 'react-redux';
import { addPesanpribadi } from './redux/reduxApi';
import { closeModal } from '../../../app/modals/redux/modalActions';
import Modal from '../../../ui/Modal';
import Button from '../../../ui/Button';

const actions = {
  closeModal,
  addPesanpribadi,
};

function ModalPreview({ data, addPesanpribadi, closeModal }) {
  const { auth, history, item } = data;

  function handleSubmit() {
    addPesanpribadi(auth, history, item);
    closeModal();
  }
  return (
    <Modal variant='info' closeModal={closeModal}>
      <div className='table-container my-3 mx-1'>
        <table className='table is-bordered is-striped is-narrow is-hoverable is-fullwidth'>
          <thead>
            <tr>
              <th className='has-text-centered'>No</th>
              <th className='has-text-centered'>NIS</th>
              <th className='has-text-centered'>Nama Siswa</th>
              <th className='has-text-centered'>Variabel</th>
              <th className='has-text-centered'>Pesan Pribadi</th>
            </tr>
          </thead>
          <tbody>
            {item?.recipients?.map((i, key) => (
              <tr key={key}>
                <td className='has-text-centered' style={{ width: 50 }}>
                  {key + 1}
                </td>
                <td>{i?.nis}</td>
                <td>{i?.name}</td>
                {i?.variable && i?.variable[0] && (
                  <td>
                    {i?.variable?.length > 0 &&
                      i?.variable?.map((v, key) => (
                        <span key={key} className='tag mr-1'>
                          {v}
                        </span>
                      ))}
                  </td>
                )}
                {!i?.variable?.length && <td className='has-text-centered'>-</td>}
                <td>
                  <p style={{ whiteSpace: 'pre-wrap' }}>{i?.messages}</p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className='flex justify-end align-center pt-2'>
        <div className='buttons'>
          <Button cvariant='csave' onClick={handleSubmit} />
          <Button cvariant='cback' onClick={closeModal} />
        </div>
      </div>
    </Modal>
  );
}

export default connect(null, actions)(ModalPreview);
