import React from 'react';
import { connect } from 'react-redux';
import { deleteTugasStudentAnswer } from './redux/reduxApi';
import { closeModal } from '../../../app/modals/redux/modalActions';
import Button from '../../../ui/Button';
import Modal from '../../../ui/Modal';

const actions = {
  closeModal,
  deleteTugasStudentAnswer,
};

function ModalDeleteStudentAnswer({
  data,
  deleteTugasStudentAnswer,
  closeModal,
}) {
  const { auth, history, item } = data;

  function onDelete() {
    deleteTugasStudentAnswer(auth, history, item.tugasId, item.id);
    closeModal();
  }

  return (
    <Modal variant='danger' closeModal={closeModal}>
      <p className='my-3 mx-1'>
        Apakah Anda yakin ingin menghapus tugas yang disubmit{' '}
        {item?.siswa ?? ''}?
      </p>
      <div className='flex justify-end align-center pt-2'>
        <div className='buttons'>
          <Button cvariant='cdelete' onClick={onDelete} />
          <Button cvariant='cback' onClick={closeModal} />
        </div>
      </div>
    </Modal>
  );
}

export default connect(null, actions)(ModalDeleteStudentAnswer);
