import React, { Component } from 'react';
import Submenu from './Submenu';
import { Link } from 'react-router-dom';
import Icons from '../../../common/icons/Icons';
import ChevronDown from '../../../common/icons/ChevronDown';

class Menu extends Component {
  render() {
    const { pathname, dataMenu, dataAuth, openMenu, handleToggleSubmenu } =
      this.props;
    let url = dataMenu.alias;
    let authM = dataAuth.m;
    let authSubm = dataAuth.subm;
    const regex = new RegExp(url);
    const isActive = regex.test(pathname);
    const accessMenu = authM[dataMenu.id + '_view'];
    let rootPath = '/' + pathname.split('/').filter((e) => /\S/.test(e))[0];
    return (
      <>
        {accessMenu && (
          <li style={{paddingRight: 1}}>
            {dataMenu.subm.length === 0 && (
              <Link
                to={dataMenu.url}
                className={`disable-select is-size-6 custom-text-overflow custom-hover-light-info${
                  rootPath === dataMenu.url ? ' is-active' : ''
                }`}
              >
                <span className='flex justify-start align-center'>
                  <Icons color={'#ffffff'} icon={dataMenu.icon} />
                  {dataMenu.title}
                </span>
              </Link>
            )}
            {dataMenu.subm.length > 0 && (
              <div
                onClick={() => handleToggleSubmenu(dataMenu.title)}
                className={`has-submenu flex justify-between align-center custom-text-overflow is-size-6 disable-select${
                  isActive === true
                    ? ' custom-background-active'
                    : ' has-text-light'
                }`}
              >
                <span className='has-submenu flex justify-start align-center' style={{paddingLeft: 5}}>
                  <Icons color={'#ffffff'} icon={dataMenu.icon} />
                  {dataMenu.title}
                </span>
                <span
                  className={`mr-2 ${
                    openMenu.title === dataMenu.title && openMenu.open
                      ? 'arrow'
                      : 'arrow-rotated'
                  }`}
                >
                  <ChevronDown size={15} color={'#ffffff'} />
                </span>
              </div>
            )}
            <ul
              className={
                openMenu.title === dataMenu.title && openMenu.open
                  ? 'submenu-detail submenu-detail-open'
                  : 'submenu-detail'
              }
            >
              {dataMenu.subm.map((subm) => (
                <Submenu
                  key={subm.id}
                  subm={subm}
                  authSubm={authSubm}
                  pathname={pathname}
                />
              ))}
            </ul>
          </li>
        )}
      </>
    );
  }
}

export default Menu;
