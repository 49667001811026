import React, { Component, Fragment } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import isAuthenticated from '../common/utilities/authWrapper';
import Navbar from '../navbar/Navbar';
import Login from '../login/Login';
import NotFound from '../common/layout/NotFound';
import ModalManager from '../modals/ModalManager';
import LoadingPage from '../common/layout/LoadingPage';

const Dashboard = React.lazy(() => import('../../menus/dashboard/Index'));
const Profile = React.lazy(() => import('../../menus/profile/Index'));
const UsersIndex = React.lazy(() => import('../../menus/users/Index'));
const UserAdd = React.lazy(() => import('../../menus/users/Add'));
const UserView = React.lazy(() => import('../../menus/users/View'));
const UserEdit = React.lazy(() => import('../../menus/users/Edit'));
const RolesIndex = React.lazy(() => import('../../menus/roles/Index'));
const RoleAdd = React.lazy(() => import('../../menus/roles/Add'));
const RoleEdit = React.lazy(() => import('../../menus/roles/Edit'));
const RoleView = React.lazy(() => import('../../menus/roles/View'));
const RppIndex = React.lazy(() => import('../../menus/_features/rpp/Index'));
const RppAdd = React.lazy(() => import('../../menus/_features/rpp/Add'));
const RppView = React.lazy(() => import('../../menus/_features/rpp/View'));
const RppEdit = React.lazy(() => import('../../menus/_features/rpp/Edit'));
const RppPublish = React.lazy(() =>
  import('../../menus/_features/rpp/Publish')
);
const MateriIndex = React.lazy(() =>
  import('../../menus/_features/materi/Index')
);
const MateriAdd = React.lazy(() => import('../../menus/_features/materi/Add'));
// const MateriAddQuestion = React.lazy(() => import('../../menus/_features/materi/AddQuestion'));
const MateriView = React.lazy(() =>
  import('../../menus/_features/materi/View')
);
const MateriEdit = React.lazy(() =>
  import('../../menus/_features/materi/Edit')
);
const MateriPublish = React.lazy(() =>
  import('../../menus/_features/materi/Publish')
);
const TugasIndex = React.lazy(() =>
  import('../../menus/_features/tugas/Index')
);
const TugasAdd = React.lazy(() => import('../../menus/_features/tugas/Add'));
const TugasEdit = React.lazy(() => import('../../menus/_features/tugas/Edit'));
const TugasView = React.lazy(() => import('../../menus/_features/tugas/View'));
const TugasViewResult = React.lazy(() =>
  import('../../menus/_features/tugas/ViewResult')
);
const TugasViewReview = React.lazy(() =>
  import('../../menus/_features/tugas/ViewReview')
);
const TugasPublish = React.lazy(() =>
  import('../../menus/_features/tugas/Publish')
);
const TugasScore = React.lazy(() =>
  import('../../menus/_features/tugas/ViewScore')
);
const TugasAnswerKey = React.lazy(() =>
  import('../../menus/_features/tugas/ViewAnswerKey')
);
const TugasAnalytic = React.lazy(() =>
  import('../../menus/_features/tugas/ViewAnalytic')
);
const TugasCopy = React.lazy(() => import('../../menus/_features/tugas/Copy'));
const TugasImport = React.lazy(() =>
  import('../../menus/_features/tugas/Import')
);
const TugasSetting = React.lazy(() =>
  import('../../menus/_features/tugas/Setting')
);
const LatihanIndex = React.lazy(() =>
  import('../../menus/_features/latihan/Index')
);
const LatihanAdd = React.lazy(() =>
  import('../../menus/_features/latihan/Add')
);
const LatihanEdit = React.lazy(() =>
  import('../../menus/_features/latihan/Edit')
);
const LatihanView = React.lazy(() =>
  import('../../menus/_features/latihan/View')
);
const LatihanViewResult = React.lazy(() =>
  import('../../menus/_features/latihan/ViewResult')
);
const LatihanViewReview = React.lazy(() =>
  import('../../menus/_features/latihan/ViewReview')
);
const LatihanPublish = React.lazy(() =>
  import('../../menus/_features/latihan/Publish')
);
const LatihanImport = React.lazy(() =>
  import('../../menus/_features/latihan/Import')
);
const TesIndex = React.lazy(() => import('../../menus/_features/tes/Index'));
const TesAdd = React.lazy(() => import('../../menus/_features/tes/Add'));
const TesCopy = React.lazy(() => import('../../menus/_features/tes/Copy'));
const TesImport = React.lazy(() => import('../../menus/_features/tes/Import'));
const TesEdit = React.lazy(() => import('../../menus/_features/tes/Edit'));
const TesView = React.lazy(() => import('../../menus/_features/tes/View'));
const TesViewResult = React.lazy(() =>
  import('../../menus/_features/tes/ViewResult')
);
const TesViewReview = React.lazy(() =>
  import('../../menus/_features/tes/ViewReview')
);
const TesPublish = React.lazy(() =>
  import('../../menus/_features/tes/Publish')
);
const TesScore = React.lazy(() =>
  import('../../menus/_features/tes/ViewScore')
);
const TesAnswerKey = React.lazy(() =>
  import('../../menus/_features/tes/ViewAnswerKey')
);
const TesAnalytic = React.lazy(() =>
  import('../../menus/_features/tes/ViewAnalytic')
);
const MonitoringIndex = React.lazy(() =>
  import('../../menus/_features/monitoring/Index')
);
const MonitoringView = React.lazy(() =>
  import('../../menus/_features/monitoring/View')
);
const CbtParticipantIndex = React.lazy(() =>
  import('../../menus/_features/cbt/participant/Index')
);
const CbtParticipantImport = React.lazy(() =>
  import('../../menus/_features/cbt/participant/Import')
);
const CbtQuestionIndex = React.lazy(() =>
  import('../../menus/_features/cbt/question/Index')
);
const CbtQuestionImport = React.lazy(() =>
  import('../../menus/_features/cbt/question/Import')
);
const CbtQuestionView = React.lazy(() =>
  import('../../menus/_features/cbt/question/View')
);
const CbtQuestionEdit = React.lazy(() =>
  import('../../menus/_features/cbt/question/Edit')
);
const CbtquestionPublish = React.lazy(() =>
  import('../../menus/_features/cbt/question/Publish')
);
const CbtquestionViewResult = React.lazy(() =>
  import('../../menus/_features/cbt/question/ViewResult')
);
const CbtquestionViewReview = React.lazy(() =>
  import('../../menus/_features/cbt/question/ViewReview')
);
const CbtquestionScore = React.lazy(() =>
  import('../../menus/_features/cbt/question/ViewScore')
);
const CalendarIndex = React.lazy(() =>
  import('../../menus/_features/calendar/Index')
);
const CalendarAdd = React.lazy(() =>
  import('../../menus/_features/calendar/Add')
);
const CalendarEdit = React.lazy(() =>
  import('../../menus/_features/calendar/Edit')
);
const CalendarImport = React.lazy(() =>
  import('../../menus/_features/calendar/Import')
);
const PortaluserIndex = React.lazy(() =>
  import('../../menus/_features/portaluser/Index')
);
const PortaluserSync = React.lazy(() =>
  import('../../menus/_features/portaluser/Sync')
);
const PortaluserView = React.lazy(() =>
  import('../../menus/_features/portaluser/View')
);
const PesanpribadiIndex = React.lazy(() =>
  import('../../menus/_features/pesanpribadi/Index')
);
const PesanpribadiAdd = React.lazy(() =>
  import('../../menus/_features/pesanpribadi/Add')
);
const PesanpribadiView = React.lazy(() =>
  import('../../menus/_features/pesanpribadi/View')
);
const PesanpribadiImport = React.lazy(() =>
  import('../../menus/_features/pesanpribadi/Import')
);
const WhatsappIndex = React.lazy(() =>
  import('../../menus/_features/whatsapp/Index')
);
const WhatsappAdd = React.lazy(() =>
  import('../../menus/_features/whatsapp/Add')
);
const WhatsappImport = React.lazy(() =>
  import('../../menus/_features/whatsapp/Import')
);
const WhatsappView = React.lazy(() =>
  import('../../menus/_features/whatsapp/View')
);
const WhatsappSetting = React.lazy(() =>
  import('../../menus/_features/whatsapp/Setting')
);
const SlideshowIndex = React.lazy(() =>
  import('../../menus/_features/slideshow/Index')
);
const SlideshowAdd = React.lazy(() =>
  import('../../menus/_features/slideshow/Add')
);
const SlideshowView = React.lazy(() =>
  import('../../menus/_features/slideshow/View')
);
const SlideshowEdit = React.lazy(() =>
  import('../../menus/_features/slideshow/Edit')
);
const AnnouncementIndex = React.lazy(() =>
  import('../../menus/_features/announcement/Index')
);
const AnnouncementAdd = React.lazy(() =>
  import('../../menus/_features/announcement/Add')
);
const AnnouncementView = React.lazy(() =>
  import('../../menus/_features/announcement/View')
);
const AnnouncementEdit = React.lazy(() =>
  import('../../menus/_features/announcement/Edit')
);
const JenjangIndex = React.lazy(() =>
  import('../../menus/_features/jenjang/Index')
);
const JenjangAdd = React.lazy(() =>
  import('../../menus/_features/jenjang/Add')
);
const JenjangView = React.lazy(() =>
  import('../../menus/_features/jenjang/View')
);
const JenjangEdit = React.lazy(() =>
  import('../../menus/_features/jenjang/Edit')
);
const TingkatIndex = React.lazy(() =>
  import('../../menus/_features/tingkat/Index')
);
const TingkatAdd = React.lazy(() =>
  import('../../menus/_features/tingkat/Add')
);
const TingkatView = React.lazy(() =>
  import('../../menus/_features/tingkat/View')
);
const TingkatEdit = React.lazy(() =>
  import('../../menus/_features/tingkat/Edit')
);
const KelasIndex = React.lazy(() =>
  import('../../menus/_features/kelas/Index')
);
const KelasAdd = React.lazy(() => import('../../menus/_features/kelas/Add'));
const KelasView = React.lazy(() => import('../../menus/_features/kelas/View'));
const KelasEdit = React.lazy(() => import('../../menus/_features/kelas/Edit'));
const KelasSync = React.lazy(() => import('../../menus/_features/kelas/Sync'));
const PelajaranIndex = React.lazy(() =>
  import('../../menus/_features/pelajaran/Index')
);
const PelajaranAdd = React.lazy(() =>
  import('../../menus/_features/pelajaran/Add')
);
const PelajaranView = React.lazy(() =>
  import('../../menus/_features/pelajaran/View')
);
const PelajaranEdit = React.lazy(() =>
  import('../../menus/_features/pelajaran/Edit')
);
const PelajaranSync = React.lazy(() =>
  import('../../menus/_features/pelajaran/Sync')
);
const PelajaranImport = React.lazy(() =>
  import('../../menus/_features/pelajaran/Import')
);
const YearIndex = React.lazy(() => import('../../menus/_features/year/Index'));
const YearAdd = React.lazy(() => import('../../menus/_features/year/Add'));
const YearView = React.lazy(() => import('../../menus/_features/year/View'));
const YearEdit = React.lazy(() => import('../../menus/_features/year/Edit'));
const SemIndex = React.lazy(() => import('../../menus/_features/sem/Index'));
const SemAdd = React.lazy(() => import('../../menus/_features/sem/Add'));
const SemView = React.lazy(() => import('../../menus/_features/sem/View'));
const SemEdit = React.lazy(() => import('../../menus/_features/sem/Edit'));
const SettingIndex = React.lazy(() =>
  import('../../menus/_features/setting/Index')
);
const Test = React.lazy(() => import('../../menus/tests/Index'));
const PrintPage = React.lazy(() => import('../../menus/tests/PrintPage'));

class App extends Component {
  render() {
    return (
      <Fragment>
        <ModalManager />
        <Fragment>
          <Navbar />
          <React.Suspense fallback={<LoadingPage />}>
            <Switch key={this.props.location.key}>
              <Route exact path='/' component={Login} />
              <Route exact path='/login' component={Login} />
              <Route
                exact
                path='/dashboard'
                component={isAuthenticated(Dashboard)}
              />
              <Route path='/profil' component={isAuthenticated(Profile)} />
              <Route
                exact
                path='/setting/user'
                component={isAuthenticated(UsersIndex)}
              />
              <Route
                exact
                path='/setting/user/tambah'
                component={isAuthenticated(UserAdd)}
              />
              <Route
                exact
                path='/setting/user/edit/:id'
                component={isAuthenticated(UserEdit)}
              />
              <Route
                exact
                path='/setting/user/:id'
                component={isAuthenticated(UserView)}
              />
              <Route
                exact
                path='/setting/role'
                component={isAuthenticated(RolesIndex)}
              />
              <Route
                exact
                path='/setting/role/tambah'
                component={isAuthenticated(RoleAdd)}
              />
              <Route
                exact
                path='/setting/role/edit/:id'
                component={isAuthenticated(RoleEdit)}
              />
              <Route
                exact
                path='/setting/role/:id'
                component={isAuthenticated(RoleView)}
              />
              {/* <Route
              exact
              path='/setting/app-setting'
              component={isAuthenticated(Appsetting)}
            /> */}
              <Route exact path='/rpp' component={isAuthenticated(RppIndex)} />
              <Route
                exact
                path='/rpp/tambah/:id'
                component={isAuthenticated(RppAdd)}
              />
              <Route
                exact
                path='/rpp/edit/:id'
                component={isAuthenticated(RppEdit)}
              />
              <Route
                exact
                path='/rpp/publish/:id'
                component={isAuthenticated(RppPublish)}
              />
              <Route
                exact
                path='/rpp/:id'
                component={isAuthenticated(RppView)}
              />
              <Route
                exact
                path='/pembelajaran/materi'
                component={isAuthenticated(MateriIndex)}
              />
              <Route
                exact
                path='/pembelajaran/materi/tambah/:id'
                component={isAuthenticated(MateriAdd)}
              />
              {/* <Route
              exact
              path='/pembelajaran/materi/tambah-pertanyaan/:id'
              component={isAuthenticated(MateriAddQuestion)}
            /> */}
              <Route
                exact
                path='/pembelajaran/materi/edit/:id'
                component={isAuthenticated(MateriEdit)}
              />
              <Route
                exact
                path='/pembelajaran/materi/publish/:id'
                component={isAuthenticated(MateriPublish)}
              />
              <Route
                exact
                path='/pembelajaran/materi/:id'
                component={isAuthenticated(MateriView)}
              />
              <Route
                exact
                path='/pembelajaran/tugas'
                component={isAuthenticated(TugasIndex)}
              />
              <Route
                exact
                path='/pembelajaran/tugas/copy'
                component={isAuthenticated(TugasCopy)}
              />
              <Route
                exact
                path='/pembelajaran/tugas/import'
                component={isAuthenticated(TugasImport)}
              />
              <Route
                exact
                path='/pembelajaran/tugas/setting'
                component={isAuthenticated(TugasSetting)}
              />
              <Route
                exact
                path='/pembelajaran/tugas/analisis/:id'
                component={isAuthenticated(TugasAnalytic)}
              />
              <Route
                exact
                path='/pembelajaran/tugas/tambah/:id'
                component={isAuthenticated(TugasAdd)}
              />
              <Route
                exact
                path='/pembelajaran/tugas/edit/:id'
                component={isAuthenticated(TugasEdit)}
              />
              <Route
                exact
                path='/pembelajaran/tugas/result/:id'
                component={isAuthenticated(TugasViewResult)}
              />
              <Route
                exact
                path='/pembelajaran/tugas/review/:id'
                component={isAuthenticated(TugasViewReview)}
              />
              <Route
                exact
                path='/pembelajaran/tugas/publish/:id'
                component={isAuthenticated(TugasPublish)}
              />
              <Route
                exact
                path='/pembelajaran/tugas/penilaian/:id'
                component={isAuthenticated(TugasScore)}
              />
              <Route
                exact
                path='/pembelajaran/tugas/kunci-jawaban/:id'
                component={isAuthenticated(TugasAnswerKey)}
              />
              <Route
                exact
                path='/pembelajaran/tugas/analisis/:id'
                component={isAuthenticated(TugasAnswerKey)}
              />
              <Route
                exact
                path='/pembelajaran/tugas/:id'
                component={isAuthenticated(TugasView)}
              />
              <Route
                exact
                path='/pembelajaran/latihan'
                component={isAuthenticated(LatihanIndex)}
              />
              <Route
                exact
                path='/pembelajaran/latihan/tambah/:id'
                component={isAuthenticated(LatihanAdd)}
              />
              <Route
                exact
                path='/pembelajaran/latihan/edit/:id'
                component={isAuthenticated(LatihanEdit)}
              />
              <Route
                exact
                path='/pembelajaran/latihan/result/:id'
                component={isAuthenticated(LatihanViewResult)}
              />
              <Route
                exact
                path='/pembelajaran/latihan/review/:id'
                component={isAuthenticated(LatihanViewReview)}
              />
              <Route
                exact
                path='/pembelajaran/latihan/publish/:id'
                component={isAuthenticated(LatihanPublish)}
              />
              <Route
                exact
                path='/pembelajaran/latihan/import'
                component={isAuthenticated(LatihanImport)}
              />
              <Route
                exact
                path='/pembelajaran/latihan/:id'
                component={isAuthenticated(LatihanView)}
              />
              <Route
                exact
                path='/pembelajaran/tes'
                component={isAuthenticated(TesIndex)}
              />
              <Route
                exact
                path='/pembelajaran/tes/copy'
                component={isAuthenticated(TesCopy)}
              />
              <Route
                exact
                path='/pembelajaran/tes/import'
                component={isAuthenticated(TesImport)}
              />
              <Route
                exact
                path='/pembelajaran/tes/tambah/:id'
                component={isAuthenticated(TesAdd)}
              />
              <Route
                exact
                path='/pembelajaran/tes/edit/:id'
                component={isAuthenticated(TesEdit)}
              />
              <Route
                exact
                path='/pembelajaran/tes/result/:id'
                component={isAuthenticated(TesViewResult)}
              />
              <Route
                exact
                path='/pembelajaran/tes/review/:id'
                component={isAuthenticated(TesViewReview)}
              />
              <Route
                exact
                path='/pembelajaran/tes/publish/:id'
                component={isAuthenticated(TesPublish)}
              />
              <Route
                exact
                path='/pembelajaran/tes/penilaian/:id'
                component={isAuthenticated(TesScore)}
              />
              <Route
                exact
                path='/pembelajaran/tes/kunci-jawaban/:id'
                component={isAuthenticated(TesAnswerKey)}
              />
              <Route
                exact
                path='/pembelajaran/tes/analisis/:id'
                component={isAuthenticated(TesAnalytic)}
              />
              <Route
                exact
                path='/pembelajaran/tes/:id'
                component={isAuthenticated(TesView)}
              />
              <Route
                exact
                path='/pembelajaran/monitoring'
                component={isAuthenticated(MonitoringIndex)}
              />
              <Route
                exact
                path='/pembelajaran/monitoring/:id'
                component={isAuthenticated(MonitoringView)}
              />
              <Route
                exact
                path='/cbt/pesertacbt'
                component={isAuthenticated(CbtParticipantIndex)}
              />
              <Route
                exact
                path='/cbt/pesertacbt/impor'
                component={isAuthenticated(CbtParticipantImport)}
              />
              <Route
                exact
                path='/cbt/soalcbt'
                component={isAuthenticated(CbtQuestionIndex)}
              />
              <Route
                exact
                path='/cbt/soalcbt/impor'
                component={isAuthenticated(CbtQuestionImport)}
              />
              <Route
                exact
                path='/cbt/soalcbt/edit/:id'
                component={isAuthenticated(CbtQuestionEdit)}
              />
              <Route
                exact
                path='/cbt/soalcbt/publish/:id'
                component={isAuthenticated(CbtquestionPublish)}
              />
              <Route
                exact
                path='/cbt/soalcbt/result/:id'
                component={isAuthenticated(CbtquestionViewResult)}
              />
              <Route
                exact
                path='/cbt/soalcbt/review/:id'
                component={isAuthenticated(CbtquestionViewReview)}
              />
              <Route
                exact
                path='/cbt/soalcbt/penilaian/:id'
                component={isAuthenticated(CbtquestionScore)}
              />
              <Route
                exact
                path='/cbt/soalcbt/:id'
                component={isAuthenticated(CbtQuestionView)}
              />
              <Route
                exact
                path='/calendar'
                component={isAuthenticated(CalendarIndex)}
              />
              <Route
                exact
                path='/calendar/tambah/:id'
                component={isAuthenticated(CalendarAdd)}
              />
              <Route
                exact
                path='/calendar/edit/:id'
                component={isAuthenticated(CalendarEdit)}
              />
              <Route
                exact
                path='/calendar/import'
                component={isAuthenticated(CalendarImport)}
              />
              <Route
                exact
                path='/portal/portaluser'
                component={isAuthenticated(PortaluserIndex)}
              />
              <Route
                exact
                path='/portal/portaluser/sync'
                component={isAuthenticated(PortaluserSync)}
              />
              <Route
                path='/portal/portaluser/:id'
                component={isAuthenticated(PortaluserView)}
              />
              <Route
                exact
                path='/portal/pesanpribadi'
                component={isAuthenticated(PesanpribadiIndex)}
              />
              <Route
                exact
                path='/portal/pesanpribadi/tambah/:id'
                component={isAuthenticated(PesanpribadiAdd)}
              />
              <Route
                exact
                path='/portal/pesanpribadi/impor/:id'
                component={isAuthenticated(PesanpribadiImport)}
              />
              <Route
                exact
                path='/portal/pesanpribadi/:id'
                component={isAuthenticated(PesanpribadiView)}
              />
              <Route
                exact
                path='/portal/whatsapp'
                component={isAuthenticated(WhatsappIndex)}
              />
              <Route
                exact
                path='/portal/whatsapp/tambah/:id'
                component={isAuthenticated(WhatsappAdd)}
              />
              <Route
                exact
                path='/portal/whatsapp/impor/:id'
                component={isAuthenticated(WhatsappImport)}
              />
              <Route
                exact
                path='/portal/whatsapp/config'
                component={isAuthenticated(WhatsappSetting)}
              />
              <Route
                exact
                path='/portal/whatsapp/:id'
                component={isAuthenticated(WhatsappView)}
              />
              <Route
                exact
                path='/portal/slideshow'
                component={isAuthenticated(SlideshowIndex)}
              />
              <Route
                exact
                path='/portal/slideshow/tambah/:id'
                component={isAuthenticated(SlideshowAdd)}
              />
              <Route
                exact
                path='/portal/slideshow/edit/:id'
                component={isAuthenticated(SlideshowEdit)}
              />
              <Route
                exact
                path='/portal/slideshow/:id'
                component={isAuthenticated(SlideshowView)}
              />
              <Route
                exact
                path='/portal/pengumuman'
                component={isAuthenticated(AnnouncementIndex)}
              />
              <Route
                exact
                path='/portal/pengumuman/tambah/:id'
                component={isAuthenticated(AnnouncementAdd)}
              />
              <Route
                exact
                path='/portal/pengumuman/edit/:id'
                component={isAuthenticated(AnnouncementEdit)}
              />
              <Route
                exact
                path='/portal/pengumuman/:id'
                component={isAuthenticated(AnnouncementView)}
              />
              <Route
                exact
                path='/akademik/jenjang'
                component={isAuthenticated(JenjangIndex)}
              />
              <Route
                exact
                path='/akademik/jenjang/tambah'
                component={isAuthenticated(JenjangAdd)}
              />
              <Route
                exact
                path='/akademik/jenjang/edit/:id'
                component={isAuthenticated(JenjangEdit)}
              />
              <Route
                exact
                path='/akademik/jenjang/:id'
                component={isAuthenticated(JenjangView)}
              />
              <Route
                exact
                path='/akademik/tingkat'
                component={isAuthenticated(TingkatIndex)}
              />
              <Route
                exact
                path='/akademik/tingkat/tambah'
                component={isAuthenticated(TingkatAdd)}
              />
              <Route
                exact
                path='/akademik/tingkat/edit/:id'
                component={isAuthenticated(TingkatEdit)}
              />
              <Route
                exact
                path='/akademik/tingkat/:id'
                component={isAuthenticated(TingkatView)}
              />
              <Route
                exact
                path='/akademik/kelas'
                component={isAuthenticated(KelasIndex)}
              />
              <Route
                exact
                path='/akademik/kelas/tambah'
                component={isAuthenticated(KelasAdd)}
              />
              <Route
                exact
                path='/akademik/kelas/edit/:id'
                component={isAuthenticated(KelasEdit)}
              />
              <Route
                exact
                path='/akademik/kelas/sync'
                component={isAuthenticated(KelasSync)}
              />
              <Route
                exact
                path='/akademik/kelas/:id'
                component={isAuthenticated(KelasView)}
              />
              <Route
                exact
                path='/akademik/pelajaran'
                component={isAuthenticated(PelajaranIndex)}
              />
              <Route
                exact
                path='/akademik/pelajaran/tambah'
                component={isAuthenticated(PelajaranAdd)}
              />
              <Route
                exact
                path='/akademik/pelajaran/edit/:id'
                component={isAuthenticated(PelajaranEdit)}
              />
              <Route
                exact
                path='/akademik/pelajaran/sync'
                component={isAuthenticated(PelajaranSync)}
              />
              <Route
                exact
                path='/akademik/pelajaran/import'
                component={isAuthenticated(PelajaranImport)}
              />
              <Route
                exact
                path='/akademik/pelajaran/:id'
                component={isAuthenticated(PelajaranView)}
              />
              <Route
                exact
                path='/akademik/year'
                component={isAuthenticated(YearIndex)}
              />
              <Route
                exact
                path='/akademik/year/tambah'
                component={isAuthenticated(YearAdd)}
              />
              <Route
                exact
                path='/akademik/year/edit/:id'
                component={isAuthenticated(YearEdit)}
              />
              <Route
                exact
                path='/akademik/year/:id'
                component={isAuthenticated(YearView)}
              />
              <Route
                exact
                path='/akademik/sem'
                component={isAuthenticated(SemIndex)}
              />
              <Route
                exact
                path='/akademik/sem/tambah'
                component={isAuthenticated(SemAdd)}
              />
              <Route
                exact
                path='/akademik/sem/edit/:id'
                component={isAuthenticated(SemEdit)}
              />
              <Route
                exact
                path='/akademik/sem/:id'
                component={isAuthenticated(SemView)}
              />
              <Route
                path='/akademik/profilsekolah'
                component={isAuthenticated(SettingIndex)}
              />
              <Route path='/test' component={Test} />
              <Route path='/test/printpage' component={PrintPage} />
              <Route path='/*' component={NotFound} />
            </Switch>
          </React.Suspense>
        </Fragment>
      </Fragment>
    );
  }
}

export default withRouter(App);
