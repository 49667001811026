export const objectToArray = (object) => {
  if (object) {
    return Object.entries(object).map((e) => Object.assign({}, e[1]));
  }
};
// get array value from array key
export const arrKeyValue = (arrMain, arrFilter, compare, result) => {
  let setArr = [];
  arrMain.forEach((main) => {
    for (let i = 0; i < arrFilter.length; i++) {
      if (main[compare] + '' === arrFilter[i] + '') {
        setArr.push(main[result]);
      }
    }
  });
  return setArr;
};
// Replace id with key of the object => for firestore
export const objectToArrayFirestore = (object) => {
  if (object) {
    return Object.entries(object).map((e) =>
      Object.assign({}, e[1], { id: e[0] })
    );
  }
};

export const sortArrayObject = (data, key, sort) => {
  if (sort === 'asc') {
    return data.sort((a, b) =>
      a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0
    );
  } else {
    return data.sort((a, b) =>
      a[key] < b[key] ? 1 : b[key] < a[key] ? -1 : 0
    );
  }
};

export const multisortArrayObject = (data, key1, key2, sort) => {
  let sortData = data;
  sortData.sort(function (a, b) {
    let aKey1 = a[key1] + '';
    let bKey1 = b[key1] + '';
    let aKey2 = a[key2] + '';
    let bKey2 = b[key2] + '';
    let af = aKey1?.toLowerCase();
    let bf = bKey1?.toLowerCase();
    let as = aKey2?.toLowerCase();
    let bs = bKey2?.toLowerCase();

    if (sort === 'asc') {
      if (af === bf) {
        return as < bs ? -1 : as > bs ? 1 : 0;
      } else {
        return af < bf ? -1 : 1;
      }
    } else {
      if (af === bf) {
        return as > bs ? -1 : as < bs ? 1 : 0;
      } else {
        return af > bf ? -1 : 1;
      }
    }
  });
  return sortData;
};

export const uniqueValueObject = (data, key) => {
  let uniqueVal = data
    .map((item) => item[key])
    .filter(
      (value, index, current_value) => current_value.indexOf(value) === index
    );
  return uniqueVal;
};

export const cleanObject = (obj) => {
  for (var propName in obj) {
    if (
      obj[propName] === '' ||
      obj[propName] === undefined ||
      obj[propName] === null
    ) {
      delete obj[propName];
    }
  }
  return obj;
};

export const arrObFilterbyOtherArrOb = (arrOb, filterArrOb, val, operation) => {
  let res = [];
  if (!operation || operation === '===') {
    res = arrOb.filter((el) => {
      return !filterArrOb.find((fl) => {
        return fl[val] === el[val];
      });
    });
  }
  if (operation === '!==') {
    res = arrOb.filter((el) => {
      return filterArrOb.find((fl) => {
        return fl[val] === el[val];
      });
    });
  }
  return res;
};
// trim left space of string
export const ltrim = (str) => {
  if (!str) return str;
  return str.replace(/^\s+/g, '');
};
