import React from 'react';
import { connect } from 'react-redux';
import { deleteTesStudentAnswer } from './redux/reduxApi';
import { closeModal } from '../../../app/modals/redux/modalActions';
import Modal from '../../../ui/Modal';
import Button from '../../../ui/Button';

const actions = {
  closeModal,
  deleteTesStudentAnswer,
};

function ModalDeleteStudentAnswer({
  data,
  deleteTesStudentAnswer,
  closeModal,
}) {
  const { auth, history, item } = data;

  function onDelete() {
    deleteTesStudentAnswer(auth, history, item.tesId, item.id);
    closeModal();
  }

  return (
    <Modal variant='danger' closeModal={closeModal}>
      <p className='my-3 mx-1'>
        Apakah Anda yakin ingin menghapus{' '}
        <span className='has-text-info has-text-weight-bold'>
          {data.submitted ? 'tes yang disubmit' : 'progress siswa'}{' '}
          {data?.item?.siswa ? data?.item?.siswa : ''}
        </span>
        ?
      </p>
      <div className='flex justify-end align-center pt-2'>
        <div className='buttons'>
          <Button cvariant='cdelete' onClick={onDelete} />
          <Button cvariant='cback' onClick={closeModal} />
        </div>
      </div>
    </Modal>
  );
}

export default connect(null, actions)(ModalDeleteStudentAnswer);
