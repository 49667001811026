import React from 'react';
import { connect } from 'react-redux';
import { statusUser } from './redux/reduxApi';
import { closeModal } from '../../app/modals/redux/modalActions';
import Modal from '../../ui/Modal';
import Button from '../../ui/Button';

const actions = {
  closeModal,
  statusUser,
};

function ModalStatus({ data, closeModal, statusUser }) {
  const { auth, history, item } = data;

  function setStatus() {
    const { status, ...rest } = item;
    const newValues = { status: !status, ...rest };
    statusUser(auth, history, newValues);
    closeModal();
  }

  return (
    <Modal variant='info' closeModal={closeModal}>
      <p className='my-3 mx-1'>
        Apakah Anda ingin set user{' '}
        <span className='has-text-danger has-text-weight-semibold is-capitalized'>
          {item.username}
        </span>{' '}
        menjadi {item.status ? 'non aktif' : 'aktif'} ?
      </p>
      <div className='flex justify-end align-center pt-2'>
        <div className='buttons'>
          <Button cvariant='csave' onClick={() => setStatus()} />
          <Button cvariant='cback' onClick={closeModal} />
        </div>
      </div>
    </Modal>
  );
}

export default connect(null, actions)(ModalStatus);
