import {
  asyncActionFinish,
  asyncActionError,
} from '../../../../app/common/reduxs/async/asyncActions';
import {
  materisGet,
  materiAdd,
  materiGet,
  materiEdit,
  materiUploadFile,
  materiDelete,
  materiResetSearch,
} from './reduxAction';
import { ASYNC_ACTION_START } from '../../../../app/common/reduxs/async/asyncConstant';
import { checkErr } from '../../../../app/common/helpers/checkRes';
import { fetchFunc } from '../../../../app/common/helpers/fetchHelpers';
import { logsFunc } from '../../../../app/common/helpers/othersHelpers';
import { SITE_ADDRESS } from '../../../../app/common/data/siteConfig';
import { saveAs } from 'file-saver';
import { toastr } from 'react-redux-toastr';

export const getMateris = (
  auth,
  history,
  itemPerPage,
  currentPage,
  searchText,
  searchMoreText,
  isReset
) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getMateris' });
    const formData = new FormData();
    if (isReset === 'reset') {
      dispatch(materiResetSearch());
    }
    const allDataAccess = auth.authorities.subm.materi_all;
    formData.append('itemPerPage', itemPerPage ? itemPerPage : 10);
    formData.append('currentPage', currentPage);
    formData.append('searchText', searchText);
    formData.append('allData', allDataAccess);
    formData.append('searchMoreText', JSON.stringify(searchMoreText));
    try {
      const res = await fetchFunc(
        'gets',
        auth,
        history,
        'materi/',
        formData,
        dispatch
      );
      const items = res.items;
      const total = res.total;
      const search = {
        currentPage,
        searchText,
        itemPerPage: itemPerPage ? itemPerPage : 10,
        ...searchMoreText,
      };
      dispatch(materisGet({ total, items, search }));
      if (isReset === 'reset') {
        history.push('/pembelajaran/materi');
      }
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const addMateri = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'addMateri' });
    let logs = logsFunc('create', auth.userid, auth.username, null);
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append(
      'description',
      values?.description ? values.description : '<p>Deskripsi...</p>'
    );
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'materi/add/' + values.id,
        formData,
        dispatch
      );
      const materi = res.materi;
      if (materi) {
        dispatch(materiAdd(materi));
        if (values.addMedia) {
          history.push('/pembelajaran/materi/edit/' + materi.id);
        } else {
          toastr.success('Success', 'Materi berhasil disimpan.');
          history.push('/pembelajaran/materi/' + materi.id);
        }
      }
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getMateri = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getMateri' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'materi/detail/' + id,
        formData,
        dispatch
      );
      const materi = res.materi;
      dispatch(materiGet(materi));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const editMateri = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'editMateri' });
    let logs = logsFunc('edit', auth.userid, auth.username, values.logs);
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'materi/edit/' + values.id,
        formData,
        dispatch
      );
      const materi = res.materi;
      dispatch(materiEdit(materi));
      history.push('/pembelajaran/materi/' + values.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const publishMateri = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'publishMateri' });
    let logs = logsFunc('edit', auth.userid, auth.username, values.logs);
    const formData = new FormData();
    formData.append('published', values.published);
    formData.append('year', values.year);
    formData.append('sem', values.sem);
    formData.append('jenjang', values.jenjang);
    formData.append('tingkat', values.tingkat);
    formData.append('kelas', values.kelas.toString());
    formData.append('pelajaran', values.pelajaran);
    formData.append('tags', JSON.stringify(values.tags));
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'materi/publish/' + values.id,
        formData,
        dispatch
      );
      const materi = res.materi;
      dispatch(materiEdit(materi));
      history.push('/pembelajaran/materi/' + values.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const uploadAttachment = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'uploadAttachment' });
    dispatch(materiUploadFile('start'));
    let materiId = values.id;
    let logs = logsFunc('edit', auth.userid, auth.username, values.logs);
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append(
      'attachments',
      values.attachments ? JSON.stringify(values.attachments) : ''
    );
    formData.append('file', values.file);
    formData.append('key', values.key);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'uploadAttach',
        auth,
        history,
        'materi/upload-attachment/' + materiId,
        formData,
        dispatch
      );
      const materi = res.materi;
      dispatch(materiEdit(materi));
      dispatch(materiUploadFile('finish'));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const uploadImage = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'uploadImage' });
    const formData = new FormData();
    formData.append('id', values.id);
    formData.append('file', values.file);
    formData.append('filename', values.filename);
    try {
      const res = await fetchFunc(
        'uploadImage',
        auth,
        history,
        'materi/upload-image/' + values.id,
        formData,
        dispatch
      );
      const url = res.url;
      dispatch(asyncActionFinish());
      return url;
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const downloadFile = (auth, history, data, file) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'downloadFile' });
    const formData = new FormData();
    formData.append('userId', auth.userId);
    formData.append('username', auth.username);
    formData.append('filelink', file.filelink);
    try {
      const fetchData = await fetch(
        SITE_ADDRESS + 'materi/download-file/' + data.id,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const blob = await fetchData.blob();
      saveAs(
        blob,
        file.filelink.split('/')[file.filelink.split('/').length - 1]
      );
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deleteFile = (auth, history, data, file) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deleteFile' });
    let logs = logsFunc('edit', auth.userid, auth.username, data.logs);
    const formData = new FormData();
    formData.append('filelink', file.filelink);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'deleteFile',
        auth,
        history,
        'materi/delete-file/' + data.id,
        formData,
        dispatch
      );
      const materi = res.materi;
      dispatch(materiEdit(materi));
      history.push('/pembelajaran/materi/edit/' + data.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deleteMateri = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deleteMateri' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'delete',
        auth,
        history,
        'materi/delete/' + id,
        formData,
        dispatch
      );
      const materi = res.materi;
      dispatch(materiDelete(materi));
      history.push('/pembelajaran/materi');
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};
