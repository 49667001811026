import React from 'react';
import { connect } from 'react-redux';
import { deleteFile } from './redux/reduxApi';
import { closeModal } from '../../../app/modals/redux/modalActions';
import Modal from '../../../ui/Modal';
import Button from '../../../ui/Button';

const actions = {
  closeModal,
  deleteFile,
};

function ModalFileDelete({ data, deleteFile, closeModal }) {
  const { auth, history, materi, file } = data;

  function handleDelete() {
    deleteFile(auth, history, materi, file);
    closeModal();
  }

  return (
    <Modal variant='danger' closeModal={closeModal}>
      <p className='my-3 mx-1'>
        Apakah Anda yakin ingin menghapus file{' '}
        <span className='has-text-danger has-text-weight-semibold'>
          {file.filename}
        </span>
        ?
      </p>
      <div className='flex justify-end align-center pt-2'>
        <div className='buttons'>
          <Button cvariant='cdelete' onClick={handleDelete} />
          <Button cvariant='cback' onClick={closeModal} />
        </div>
      </div>
    </Modal>
  );
}

export default connect(null, actions)(ModalFileDelete);
