import React from 'react';
import { closeModal } from '../../../app/modals/redux/modalActions';
import { connect } from 'react-redux';
import { format, parseISO } from 'date-fns';
import { useHistory } from 'react-router-dom';
import Button from '../../../ui/Button';

function mapState(state) {
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities;
  }
  return {
    scope: scope,
    loading: state.async.loading,
  };
}

const actions = {
  closeModal,
};

function ModalViewEvent({ closeModal, data, loading }) {
  const history = useHistory();
  const { auth, val } = data;
  const title = val.title;
  const pelajaran = val.pelajaran;
  const jenjang = val.jenjang;
  const kelas = val.kelas;
  const year = val.year;
  const sem = val.sem;
  const description = val.description;
  const publishDate = val.publishDate;
  const id = val.id;
  const type = val.type;
  const setting = val.setting && JSON.parse(val.setting);
  const time =
    type === 'tes' && setting && setting.time ? setting.time : undefined;
  const creator = val.creator && JSON.parse(val.creator);
  const creatorUserid = creator && creator.userid;

  const handleLink = () => {
    closeModal();
    if (type === 'tugas') {
      history.push('/pembelajaran/tugas/' + id);
    } else {
      history.push({
        pathname: `/pembelajaran/monitoring/${id}`,
        state: {
          auth,
          val,
        },
      });
    }
  };

  return (
    <div className='modal is-active'>
      <div className='modal-background' onClick={closeModal}></div>
      <div
        className='modal-content flex justify-center'
        style={{ overflow: 'hidden' }}
      >
        <div style={{ width: 412, minWidth: 'max-content' }}>
          <div className='' style={{ overflow: 'hidden' }}>
            <div className='card'>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingTop: 8,
                  paddingRight: 8,
                  overflow: 'hidden',
                }}
              >
                <button
                  onClick={closeModal}
                  className='delete'
                  aria-label='close'
                ></button>
              </div>
              <div
                className='card-content'
                style={{ padding: '0px 17px 20px 15px' }}
              >
                <div className='media' style={{ alignItems: 'center' }}>
                  <div className='media-left'>
                    <figure className='image is-48x48'>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: 40,
                          height: 40,
                          fontSize: 18,
                          borderRadius: 25,
                          color: 'white',
                          backgroundColor:
                            type === 'tugas' ? '#00947e' : '#3e8ed0',
                        }}
                      >
                        {type === 'tugas' ? 'Tgs' : 'Tes'}
                      </div>
                    </figure>
                  </div>
                  <div className='media-content'>
                    <p
                      className='has-text-grey-dark has-text-weight-semibold is-size-6'
                      style={{ wordWrap: 'break-word', maxWidth: 316 }}
                    >
                      {title}
                    </p>
                    <p className='has-text-grey is-size-7'>{pelajaran}</p>
                  </div>
                </div>
                <div className='content' style={{ marginTop: -12 }}>
                  <p className='has-text-grey-darker is-capitalized is-size-7'>
                    {description}{' '}
                  </p>
                  <div
                    className='has-text-grey-dark is-size-7 flex justify-between'
                    style={{ marginTop: -4 }}
                  >
                    <div className='mb-2'>
                      <span className='tag is-rounded is-info is-light mr-2 mb-1'>
                        {jenjang}
                      </span>
                      <span className='tag is-rounded is-info is-light mr-2 mb-1'>
                        {kelas}
                      </span>
                      <span className='tag is-rounded is-info is-light mr-2 mb-1'>
                        {year}
                      </span>
                      <span className='tag is-rounded is-info is-light mb-1'>
                        Sem {sem}
                      </span>
                    </div>
                    {type === 'tugas' &&
                      (auth.username === 'sa' ||
                        creatorUserid + '' === auth.userId + '') && (
                        <Button variant='view' onClick={handleLink} />
                      )}
                    {type !== 'tugas' && (
                      <Button variant='view' onClick={handleLink} />
                    )}
                  </div>
                  <p className='has-text-grey-dark is-size-7'>
                    {publishDate &&
                      format(parseISO(publishDate), 'd LLLL yyyy - HH:mm:ss')}
                    {time ? ` (${time} menit)` : ''}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(mapState, actions)(ModalViewEvent);
